@import '../../styles/variables.scss';

.home{
    height: 100vh;
    position: relative;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    &__content{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    &__title {
        font-size: 50px;
        font-weight: 300;
        font-family: 'Rubik', sans-serif;
        text-transform: uppercase;
        letter-spacing: 4px;
        div {
            margin: 10px 0;
            font-size: 65px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            word-spacing: 2px;
        }
    }
    &__subtitle {
        font-size: 16px;
        font-weight: 300;
        font-family: 'Raleway', sans-serif;
        line-height: 1.8;
        color: $gallery;
        word-spacing: 2px;
    } 
    &__button {
        margin-top: 30px;
    }
    &__border{
        position: absolute;
        bottom: 50px;
        width: 100%;
        &::after, &::before{
            content: '';
            position: absolute;
            border-bottom: 50px solid $white; 	
        }
        &::after{
            border-left: 50vw solid transparent;
            right: 0;
        }
        &::before{
            border-right: 50vw solid transparent;	
        }
    }
    &::before{
        content: '';
        display: block;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;      
        opacity: 0.8;
        background: linear-gradient(to bottom right, $jacksons-purple , $dodger-blue);
    }

    @media screen and (max-width: 767px) {
        &__title {
            font-size: 14px;
            div {
                font-size: 25px;
            }
        }
        &__subtitle{
            font-size: 16px;
        }
    }

}
