@import './variables';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: black;
}
ul,
li,
dl {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1199px) {
        max-width: 960px;
    }

    @media screen and (max-width: 991px) {
        max-width: 720px;
    }

    @media screen and (max-width: 767px) {
        max-width: 540px;
    }

    @media screen and (max-width: 575px) {
        max-width: 100%;
    }
}

.divider{
    height: 60px;
    width: 100%;
    background-color: $white;
    &_large{
        height: 120px;
    }
}


