@import '../../styles/variables.scss';

.services {
    color: $white;
    padding: 50px 0;
    position: relative;
    background-color: $alabaster;
    .container {
        position: relative;
    }
    &__title {
        text-align: center;
        font-family: 'Rubik', sans-serif;
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 15px;
        color: $black;
        margin-bottom: 80px;
        span {
            font-weight: 500;
        }
    }
    &__content-wrapper{
        display: flex;
        justify-content: space-between;
    }
    &__image{
        width: 50%;
        padding: 0 15px;
        img{
            border-radius: 5px;
            box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
            width: 100%;
            object-fit: contain;
        }
    }
    &__content{
        width: 50%;
        padding: 0 15px;
        p {
            font-family: 'Raleway', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: $dusty-gray;
            line-height: 1.8;
            margin-bottom: 30px;
        }
    }

    &__cards{
        display: grid;
        grid-template-columns: repeat(3,33.33%);
        gap: 40px 10px;
    }
    &__card {
        text-align: center;
        padding: 30px 5px;
        color: $black;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: 500;
        font-size: 18px;
        font-family: 'Rubik', sans-serif;
        background-color: $light-alabaster;
        transition: all .3s ease;
        &:hover {
            background-color: $jacksons-purple;
            color: $white;
            svg path {
                fill: $white;
            }
        }
        svg {
            height: 30px;
            width: 30px;
            margin-bottom: 15px;
            path {
                fill: $jacksons-purple;
                transition: all .3s ease;
            }
        }
    }
    &__upper-border{
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
        &::before, &::after{
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-top: 50px solid $white;
        }
        &::before{
            border-right: 30vw solid transparent;
        }
        &::after{
            right: 0;
            border-left: 70vw solid transparent;
        }
    }
    &__bottom-border{
        position: relative;
        z-index: 5;
        &::before, &::after{
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-bottom: 50px solid $white;
        }
        &::before{
            border-right: 70vw solid transparent;
        }
        &::after{
            right: 0;
            border-left: 30vw solid transparent;
        }
    }

    @media screen and (max-width: 991px) {
        &__content-wrapper{
            flex-direction: column;
        }
        &__image{
            width: 100%;
            margin-bottom: 50px;
        }
        &__content{
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        &__cards{
            gap: 40px 0px;
        }
    }

    @media screen and (max-width: 575px) {
        &__cards{
            grid-template-columns: 100%;
        }
        &__content{
            padding: 0;
            p {
                padding: 0 15px;
            }
        }
    }
}