@import '../../styles/variables.scss';

.contacts {
    padding: 60px;
    background-color: $white;
    font-family: 'Rubik', sans-serif;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__heading {
        width: 50%;
        text-align: center;
        margin-bottom: 80px;
    }
    &__title {
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 15px;
        span {
            font-weight: 500;
        }
    }
    &__subtitle {
        font-family: 'Raleway', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.8;
        color: $dusty-gray;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        width: 100%;
        &-addresses{
            width: calc(40% - 10px);
            span {
                font-weight: 500;
            }
        }
        &-feedback{
            width: calc(60% - 10px);
        }
    }
    &__item {
        display: flex;
        margin-top: 30px;
        &-icon {
            position: relative;
            width: 50px;
            height: 100%;
            svg {
                position: absolute;
                left: 0;
                top: 0;
                height: 30px;
                path {
                    fill: $jacksons-purple;
                }
            }
        }
        &-info{
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            flex: 1;
            a {
                display: block;
                text-decoration: none;
                color: $black;
                transition: all 0.3s ease;
            }
            &[input-type="email"]{
                & a:hover {
                    color: $dodger-blue;
                }
            }
        }
        &-title{
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-weight: 500;
        }
    }
    .form button {
        display: none;
    }
    
    @media screen and (max-width: 767px) {
        &__heading {
            width: 75%;
            text-align: center;
            margin-bottom: 50px;
        }
        &__content {
            flex-direction: column;
            &-addresses {
                width: 100%;
                margin-bottom: 50px;
            }
            &-feedback {
                width: 100%;
            }
        }
        .form button {
            display: block;
        }
        .container > button {
            display: none;
        }
    }

    @media screen and (max-width: 575px) {
        &__heading {
            width: 100%;
            text-align: center;
        }
    }
}