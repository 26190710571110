$jacksons-purple: #1A237E;
$dodger-blue: #2576FD;
$white: #fff;
$black: #000;
$gallery: #eee;
$dusty-gray: #999;
$alabaster: #f9f9f9;
$concrete: #f2f2f2;
$light-alabaster: #f7f7f7;
$silver: #ccc;
$dark-gray: #222;
$dark-silver: #777;
$night-gray: #111