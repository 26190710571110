@import '../../styles/variables.scss';

.progress {
    &__label{
        font-size: 14px;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        margin-bottom: 10px;
    }
    &__bar{
        background: linear-gradient(to bottom right, $dodger-blue, $jacksons-purple);
        transition: width 2s ease;
        height: 9px;
        border-radius: inherit;
        position: relative;
        &::after{
            content: attr(data-value);
            font-family: 'Rubik', sans-serif;
            color: $black;
            position: absolute;
            top: -15px;
            right: 0;
            font-size: 11px;
        }
    }
    &__bg{
        background: $alabaster;
        border-radius: 12px;
        width: 100%;
        flex: 1;
        height: 9px;
    }
    
}